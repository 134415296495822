import React from "react";
import { Trans } from "react-i18next";

export default function ChristmasSchedule() {
	return (
		<>
			<div>
				<span className="bold">
					<Trans>christmas opening hours</Trans>.
				</span>
			</div>
			<div>
				<span>
					<Trans>mon 23rd</Trans>.
				</span>
				<br />
				<span>
					{/* <Trans>special brunch with Santa</Trans>.
					<br /> */}
					<Trans>10am to 04.30pm</Trans>
					{/* <span className="light"> kitchen 10h to 14h.</span> */}
				</span>
			</div>
			<div>
				<span>
					<Trans>tue 24th</Trans>.
				</span>
				<br />
				<span>
					{/* <Trans>special brunch with Santa</Trans>.
					<br /> */}
					<Trans>10am to 04.30pm</Trans>
					{/* <span className="light"> kitchen 10h to 14h.</span> */}
				</span>
			</div>
			<div>
				<span>
					<Trans>wed 25th</Trans>.
				</span>
				<br />
				<span>
					{/* <Trans>special brunch with Santa</Trans>.
					<br /> */}
					<Trans>10am to 04.30pm</Trans>
					{/* <span className="light"> kitchen 10h to 14h.</span> */}
				</span>
			</div>
			<div>
				<span className="bold">
					<Trans>new year opening hours</Trans>.
				</span>
			</div>		
			<div>
				<span>
					<Trans>tue 31st New Year's Eve</Trans>.
				</span>
				<br />
				<span>
					<Trans>daytime closed</Trans>.<br />
					<Trans>07.00pm to 02am</Trans>.
					{/* <span className="light">
						{" "}
						<Trans>fully booked</Trans>.
					</span> */}
				</span>
			</div>
			<div>
				<span>
					<Trans>wed 1st New Year '25</Trans>.
				</span>{" "}
				<br />
				<span>
					<Trans>no breakfast</Trans>.<br />
					12pm to 04.30pm.
					{/* <span className="light"> kitchen 12h to 16h.</span> */}
				</span>
			</div>
		</>
	);
}
