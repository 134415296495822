// function exports for use in other pages
export const addCookie = (cookieName, durationDays = false) => {
	// default duration is one year (in seconds)
	let expires = "";

	if (durationDays) {
		const d = new Date();
		d.setTime(d.getTime() + durationDays * 24 * 60 * 60 * 1000);
		expires = "expires=" + d.toUTCString();
	}

	// console.log("addCookies running");
	const acceptAppendix = "=true;path=/;SameSite=none;Secure;Expires=" + expires;
	// console.log(acceptAppendix);

	if (typeof document != "undefined") {
		document.cookie = cookieName + acceptAppendix;
	}
};
// export const rejectCookies = () => {
// 	// console.log("rejectCookies running");
// 	const oneYear = 365 * 24 * 60 * 60 * 1000; //one year in seconds, to set expiry date for the cookie
// 	const rejectAppendix =
// 		"=false;path=/;SameSite=none;Secure;Max-Age=" + oneYear;

// 	if (typeof document != "undefined") {
// 		document.cookie =
// 			"cookiesStored=true;path=/;SameSite=none;Secure;Max-Age=" + oneYear;
// 		document.cookie = "cookiesAccepted" + rejectAppendix;
// 		document.cookie = "gatsby-gdpr-google-tagmanager" + rejectAppendix;
// 	}
// };
export const getCookie = (name) => {
	if (typeof document != "undefined") {
		// console.log(document);
		var v = document?.cookie?.match("(^|;) ?" + name + "=([^;]*)(;|$)");
		// console.log(v);
		if (v) {
			if (v[2] === "true") {
				return true;
			} else if (v[2] === "false") {
				return false;
			} else {
				return v[2];
			}
		}
	}
	return null;
};
